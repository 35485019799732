#lightbox_overlay {
    background-color: hsl(227deg 40% 16%);

    .image-preview {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: calc(100% - 65px - 95px);
        margin: 0;
        overflow: hidden;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        & img {
            cursor: move;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }

        .zoom-element {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .video-player {
        display: flex;
        width: 100%;
        height: calc(100% - 65px - 95px);
        align-items: center;
        justify-content: center;
        margin: 0;
        overflow: hidden;

        & video {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .exit {
        flex-shrink: 0;

        color: hsl(0deg 0% 100% / 80%);
        font-size: 2rem;
        margin: 6px 20px 0 0;

        transform: scaleY(0.75);
        font-weight: 300;

        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    &.show .exit {
        pointer-events: auto;
        opacity: 1;
    }

    .media-info-wrapper {
        background-color: transparent;
        display: flex;
        flex-flow: row wrap;
    }

    .media-description,
    .media-actions {
        margin: 20px;
    }

    .media-actions {
        flex-shrink: 0;
        margin-left: auto;

        .button {
            font-size: 0.9rem;
            min-width: inherit;
            padding: 4px 10px;
            border: 1px solid hsl(0deg 0% 100% / 60%);
            background-color: transparent;
            color: hsl(0deg 0% 100%);
            border-radius: 4px;
            text-decoration: none;
            display: inline-block;
            margin: 0 5px;

            &:hover {
                background-color: hsl(0deg 0% 100%);
                border-color: hsl(0deg 0% 100%);
                color: hsl(227deg 40% 16%);
            }
        }

        .disabled {
            opacity: 0.7;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: hsl(0deg 0% 100%);
                border: 1px solid hsl(0deg 0% 100% / 60%);
            }
        }
    }

    .media-description {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 400px);
        /* add some extra margin top and remove some bottom to keep the
        height the same. and vertically center the text with the buttons. */
        margin-top: 25px;
        margin-bottom: 15px;

        font-size: 1.1rem;
        color: hsl(0deg 0% 100%);

        .title {
            vertical-align: top;
            font-weight: 400;
            line-height: normal;

            /* Required for text-overflow */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .user {
            font-weight: 300;
            line-height: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;

            &::before {
                margin-right: 5px;
                content: "\2014";
            }
        }
    }

    .player-container {
        height: calc(100% - 65px - 95px);
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        & iframe {
            /* maintain 16:9 ratio. */
            width: calc((100vh - 65px - 95px) * 16 / 9);
            height: 100%;
            margin: auto;
        }
    }

    .center {
        .arrow {
            display: inline-block;
            vertical-align: top;
            margin-top: 25px;
            padding: 5px 10px;

            color: hsl(0deg 0% 100%);
            font-size: 1.8em;
            font-weight: 200;

            transform: scaleY(2);
            cursor: pointer;

            opacity: 0.5;
            transition: all 0.3s ease;

            &:hover {
                opacity: 1;
            }
        }

        .image-list {
            position: relative;
            display: inline-block;
            padding: 15px 0 12px;
            height: 50px;
            font-size: 0;

            max-width: 40vw;
            overflow: hidden;
            white-space: nowrap;

            .image {
                display: inline-block;
                vertical-align: top;
                width: 50px;
                height: 50px;
                margin: 0 2px;

                background-color: hsl(0deg 0% 94% / 20%);
                opacity: 0.5;

                background-size: cover;
                background-position: center;
                cursor: pointer;

                &.selected {
                    opacity: 1;
                }
            }

            .lightbox_video video {
                width: 50px;
                height: 50px;
            }
        }
    }
}

@media only screen and ($ms_min <= width < $md_min) {
    #lightbox_overlay {
        .media-actions {
            width: 100%;
            padding-left: 15px;
            margin-top: 0;
        }

        .media-description {
            max-width: calc(100% - 100px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
        }

        .center .image-list {
            max-width: 80vw;
        }

        .player-container iframe {
            /* maintain 16:9 ratio. */
            width: 100%;
            height: calc((100vw) * 9 / 16);
            margin: auto;
        }

        .image-preview {
            height: calc(100% - 101px - 104px);
        }

        .media-info-wrapper {
            align-items: flex-end;
        }

        .exit {
            position: absolute;
            right: 5px;
            top: 6px;
        }
    }
}
